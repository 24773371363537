const MSG_PREFIX = 'innerwell-rn-message:';

// function isRNMessage(msg: unknown): msg is string {
//    return typeof msg === 'string' && msg.startsWith(MSG_PREFIX);
// }

// export const parseRNResponse = <T extends keyof typeof rnResponseSchemas>(
//    type: T,
//    msg: unknown,
// ) => {
//    if (!isRNMessage(msg)) {
//       return null;
//    }

//    const respObj = JSON.parse(msg.replace(MSG_PREFIX, ''));

//    const parseAttempt = rnResponseSchemas[type].safeParse(respObj);

//    if (!parseAttempt.success) {
//       return null;
//    }

//    return parseAttempt.data;
// };

export const postToRN = (rnMessage: unknown) => {
   window.ReactNativeWebView?.postMessage(
      `${MSG_PREFIX}${JSON.stringify(rnMessage)}`,
   );
};

declare global {
   interface Window {
      ReactNativeWebView?: {
         postMessage: (message: string) => void;
      };
   }
}
