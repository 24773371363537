'use client';

import { usePushToRN } from '@/services/rn/hooks/use-push-to-rn';
import { useEffect } from 'react';

export const RNLayoutColorChange = ({
   headerColor,
   footerColor,
}: {
   headerColor: string;
   footerColor: string;
}) => {
   const { send: layoutChange } = usePushToRN('layout-change');

   useEffect(() => {
      layoutChange({
         headerColor,
         footerColor,
      });
   }, [layoutChange, headerColor, footerColor]);

   return null;
};
