import { Global } from '@emotion/react';

export function Fonts() {
   return (
      <Global
         styles={`
            /* latin */
            @font-face {
               font-family: 'Cannon';
               font-style: normal;
               font-weight: 400;
               font-display: swap;
               src: url('/fonts/Cannon/Cannon-Regular.woff2') format('woff2'), url('/fonts/Cannon/cannon-regular.woff') format('woff');
               unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            @font-face {
               font-family: 'TTCommonsPro';
               font-style: normal;
               font-weight: 400;
               font-display: swap;
               src: url('/fonts/TTCommons/TTCommonsProRg/font.woff2') format('woff2'), url('/fonts/TTCommons/TTCommonsProRg/font.woff') format('woff');
            }
            @font-face {
               font-family: 'TTCommonsPro';
               font-style: normal;
               font-weight: 500;
               font-display: swap;
               src: url('/fonts/TTCommons/TTCommonsProMd/font.woff2') format('woff2'), url('/fonts/TTCommons/TTCommonsProMd/font.woff') format('woff');
            }
            @font-face {
               font-family: 'TTCommonsPro';
               font-style: normal;
               font-weight: 600;
               font-display: swap;
               src: url('/fonts/TTCommons/TTCommonsProDb/font.woff2') format('woff2'), url('/fonts/TTCommons/TTCommonsProDb/font.woff') format('woff');
            }
            body {
               -webkit-font-smoothing: antialiased;
               -moz-osx-font-smoothing: grayscale;
               text-rendering: optimizeSpeed;
            }
         `}
      />
   );
}
