import {
   type GroupBase,
   type Props,
   Select,
   type SelectInstance,
} from 'chakra-react-select';
import {
   forwardRef,
   type ReactElement,
   type Ref,
   type RefAttributes,
} from 'react';

type AutoCompleteSelectProps<
   Option = unknown,
   IsMulti extends boolean = false,
   Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group>;

const AutocompleteSelectWithRef = <
   Option = unknown,
   IsMulti extends boolean = false,
   Group extends GroupBase<Option> = GroupBase<Option>,
>(
   props: AutoCompleteSelectProps<Option, IsMulti, Group>,
   ref: Ref<SelectInstance<Option, IsMulti, Group>>,
) => {
   return (
      <Select
         variant="outline"
         chakraStyles={{
            container: (provided) => ({
               ...provided,
               cursor: 'pointer',
               minW: '300px',
               zIndex: 100,
            }),
            option: (provided) => ({
               ...provided,
               fontSize: '.9rem',
               color: 'black',
               _active: {
                  color: 'white',
                  bg: 'background.primary',
               },
               _selected: {
                  color: 'white',
                  bg: 'background.primary',
               },
            }),
            menuList: (provided) => ({
               ...provided,
               mb: 3,
               maxHeight: '300px',
            }),
            valueContainer: (provided) => ({
               ...provided,
               padding: '0 12px',
               fontSize: '.9rem',
            }),
            control: (provided) => ({
               ...provided,
               bg: 'background.secondary',
               _hover: {
                  bg: 'background.secondary',
               },
               _active: {
                  bg: 'background.secondary',
               },
               _focus: {
                  bg: 'background.secondary',
               },
            }),

            dropdownIndicator: (provided) => ({
               ...provided,
               boxShadow: 'none',
            }),
         }}
         isClearable={false}
         {...props}
         ref={ref}
      />
   );
};

// Using idea with casting from https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const AutocompleteSelect = forwardRef(AutocompleteSelectWithRef) as <
   Option = unknown,
   IsMulti extends boolean = false,
   Group extends GroupBase<Option> = GroupBase<Option>,
>(
   p: AutoCompleteSelectProps<Option, IsMulti, Group> &
      RefAttributes<SelectInstance<Option, IsMulti, Group>>,
) => ReactElement;
