import { Link, type LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { type Route } from 'next';
import NLink from 'next/link';

const NextLink = <T extends string = string>(
   props: Omit<ChakraLinkProps, 'href'> & { href: Route<T> },
) => {
   const { href, ...rest } = props;

   // We are tricking NextJS that we use Route because on admin-web were still using pages router
   return (
      <NLink href={href} legacyBehavior>
         <Link {...rest} />
      </NLink>
   );
};

export default NextLink;
