import { useRef, useCallback } from 'react';

import { postToRN } from '../rn-msg';
import type { RNRequestSchema } from '../types/rn-requests';
import { type z } from 'zod';

const generateRequestId = () => `${Date.now()}-${Math.random()}`;

export function usePushToRN<T extends keyof RNRequestSchema>(eventName: T) {
   const requestIdRef = useRef(generateRequestId());
   const sendCallback = useCallback(
      (data?: z.infer<RNRequestSchema[T]['data']>) => {
         postToRN({ id: requestIdRef.current, type: eventName, data });
      },
      [eventName],
   );

   return {
      send: sendCallback,
   };
}
